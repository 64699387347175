/* eslint-disable react-hooks/rules-of-hooks */
import {
  loginWithCode,
  refreshCode,
  UserAccountContext,
  UserAccountModal,
} from '@finn/ua-auth';
import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { ModalKey, useOpenModal } from '@finn/ua-modals';
import { AvailableFilters, Brand, useGetPreviousCart } from '@finn/ua-vehicle';
import {
  CookieKeys,
  getLocaleFromContext,
  Locale,
  trackEvent,
  useSession,
} from '@finn/ui-utils';
import Cookies from 'js-cookie';
import isArray from 'lodash/isArray';
import { GetStaticProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import React, { ReactNode, useContext, useEffect, useRef } from 'react';

import { useRetentionHomePageData } from '~/modules/home/useRetentionHomePageData';
import { BasePageData } from '~/types/cosmicModules';
import { Review } from '~/types/reviews';
import PageBodyBuilder from '~/ui-modules/PageBodyBuilder';

import { USDeprecationNoticeContainer } from '../components/RedirectionContainer/USDeprecationNoticeContainer';
import { getLandingPageGetServerSideProps } from '../utils/landingPage';

export interface IProps {
  pageData: BasePageData;
  reviews: Review[];
  brands: Brand[];
  renderMetaContent?: () => ReactNode;
  locale: string;
  allAvailableFilters?: AvailableFilters;
}

export const homePageldScripts = [
  {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    url: 'https://www.finn.com/de-DE',
    name: 'FINN Auto Abo',
    description:
      'FINN bietet Auto-Abos inklusive Versicherung, Steuer und TÜV mit Haustürlieferung zum Wunschdatum.',
  },
  {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: 'FINN Auto Abo',
    url: 'https://www.finn.com/de-DE',
  },
  {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'FINN Auto Abo',
    url: 'https://www.finn.com/de-DE',
    logo: '',
    sameAs: [
      'https://www.facebook.com/finn.auto.abo/',
      'https://www.instagram.com/finn.auto/',
      'https://www.youtube.com/c/FINNauto',
      'https://www.linkedin.com/company/finn-auto',
    ],
  },
];

export const HomePage: NextPage<IProps> = ({
  pageData,
  reviews,
  brands,
  locale,
  renderMetaContent,
}) => {
  if (!pageData?.metadata?.modules) return null;

  const router = useRouter();
  const [session] = useSession();
  const { setModalStatus } = useContext(UserAccountContext);
  const { retentionPageData } = useRetentionHomePageData();
  const openModal = useOpenModal();

  const removeUrlQuery = () => {
    router.replace(`/`, undefined, {
      shallow: true,
    });
  };

  useEffect(() => {
    const login = async (code: string) => {
      await loginWithCode(code);
      trackEvent('Account Logged In', { loginMethod: 'code' });
      await refreshCode();
    };

    const verificationCode = Cookies.get(CookieKeys.AUTH_EMAIL_VERIFIED);
    if (verificationCode) {
      login(verificationCode);
      setModalStatus({
        isModalOpen: true,
        modalType: UserAccountModal.EMAIL_VERIFIED_SUCCESSFULLY,
      });
      Cookies.remove(CookieKeys.AUTH_EMAIL_VERIFIED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  const previousCart = useGetPreviousCart();
  const returnToCkoOpened = useRef(false);
  const isCustomiseExp = useIsABVariant(Features.ExpCustomization);
  useEffect(() => {
    const reminderDismissed = Cookies.get(CookieKeys.REMINDER_DISMISSED);
    if (
      previousCart &&
      isCustomiseExp &&
      !returnToCkoOpened.current &&
      !reminderDismissed
    ) {
      openModal(ModalKey.RETURN_TO_CHECKOUT);
      returnToCkoOpened.current = true;
    }
  }, [previousCart, isCustomiseExp]);

  useEffect(() => {
    const gotoNext = (path: string | string[] | undefined) => {
      const nextPath = isArray(path) ? path[0] : path;
      if (nextPath) {
        const fullUrl = nextPath?.startsWith('/portal')
          ? `https://${window.location.host}/${nextPath}`
          : `https://${window.location.host}/${router.locale}/${nextPath}`;
        window.location.replace(fullUrl);
      } else {
        router.replace('/');
      }
    };
    const { login, next, modal } = router.query;
    if (login === 'true' && session?.user?.email_verified) {
      gotoNext(next);
    }

    if (modal) {
      setModalStatus({
        isModalOpen: true,
        modalType: modal as UserAccountModal,
        options: {
          onClose: () => {
            // To prevent modal from reshowing on page refresh
            removeUrlQuery();
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, session?.user?.email_verified]);

  const jsonLdContentArr =
    locale === Locale.GERMAN_GERMANY
      ? homePageldScripts.map((item) => JSON.stringify(item, null, 4))
      : [];

  return (
    <USDeprecationNoticeContainer>
      <PageBodyBuilder
        data={retentionPageData ? retentionPageData : pageData}
        reviews={reviews}
        brands={brands}
        renderMetaContent={renderMetaContent}
        jsonLdContentArr={jsonLdContentArr}
      />
    </USDeprecationNoticeContainer>
  );
};

export const getStaticProps: GetStaticProps = async (ctx) => {
  const locale = getLocaleFromContext(ctx);
  const slug = 'home-new';

  const props = await getLandingPageGetServerSideProps(slug, locale);

  return {
    props: {
      ...props,
      locale,
    },
  };
};

export default HomePage;
